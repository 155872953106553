import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

function Questions({ allQuestions }) {
  console.log({ allQuestions });

  const handelSelectedOptionColor = (option, question) => {
    if (question.rightAnswer === option) return "green";
    if (question.userAnswer === option) return "red";
  };

  return (
    <>
      {allQuestions.map((question, index) => {
        return (
          <Box
            sx={{
              boxShadow: 3,
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#101010" : "#fff",
              color: (theme) =>
                theme.palette.mode === "dark" ? "grey.300" : "grey.800",
              p: "20px 30px",
              m: "40px 6px",
              borderRadius: 2,
            }}
          >
            <FormControl>
              <FormLabel
                id="demo-radio-buttons-group-label"
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {" "}
                {index + 1} - {question.title}
              </FormLabel>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={question.rightAnswer}
                name="radio-buttons-group"
              >
                {question.questionType === "MCQ" &&
                  question.questionOptions.map((option) => {
                    return (
                      <FormControlLabel
                        control={
                          <Radio checked={question.userAnswer === option} />
                        }
                        label={
                          <Box component="div" fontSize={25}>
                            {option}
                          </Box>
                        }
                        sx={{
                          color: handelSelectedOptionColor(option, question),
                        }}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
            <Typography variant="h5" component="h2" padding={"15px  "}>
              ملاحظات : {question.answerNote}
            </Typography>
            <Typography variant="h5" component="h2" padding={"3px 15px 15px"}>
              درجه السؤال : {question.degree}
            </Typography>
          </Box>
        );
      })}
    </>
  );
}

export default Questions;
