import React, { useEffect, useState, useCallback } from "react";
import { authorizedAPIs } from "../../API/axiosSetup";
import { useParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import { Typography } from "@mui/material";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";

const initialHeadCells = [
  {
    id: "fullName",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "email",
    numeric: false,
    label: "الايميل",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
];

function UsersInExam() {
  const { _id } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [numberOfStudent, setNumberOfStudent] = useState();

  const getStudentInExam = useCallback(() => {
    const skip = page * rowsPerPage;

    authorizedAPIs
      .get(
        `/exercises/admin/getStudentInExercise/${_id}?limit=${rowsPerPage}&skip=${skip}`
      )
      .then((res) => {
        console.log({ res });
        setUsers(res.data.result);
        setNumberOfStudent(res.data.numberOfStudent);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  }, [_id, page, rowsPerPage]);

  useEffect(() => {
    getStudentInExam();
  }, [_id, page, rowsPerPage]);

  const deleteFromExam = (users_ids) => {
    authorizedAPIs
      .put(`/exam/admin/disallowExercise/${_id}`, { users_ids })
      .then((res) => {
        getStudentInExam();
        dispatch(showAlert("تم حذف الامتحان بنجاح", "success"));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Typography
            style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
          >
            أختار طالب لحذفه من الامتحان
          </Typography>
          <EnhancedTable
            withEdit={false}
            withConfirm
            manyAction={deleteFromExam}
            manyActionLabel={"delete-from-exam"}
            initialRows={users}
            headCells={initialHeadCells}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            setPage={setPage}
            count={numberOfStudent}
            path={"/users/"}
          />
        </>
      )}
    </div>
  );
}

export default UsersInExam;
