import React from 'react';
import { IconButton, Modal, Tooltip } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";


const AlertWithOptions = ({ action, handleClose, message, isShown, tooltipTitle = "حذف", icon = <DeleteIcon /> }) => {

    return (
        <Modal
            open={isShown}
            onClose={handleClose}
            
        >
            <Alert severity="warning" className="alert"  style={{display:"flex",justifyContent:"end"}}>
                <AlertTitle> تحذير </AlertTitle>
                <span className="alert-message"> {message} </span>
                <br />
                <div className='alert-actions'>
                    <Tooltip title={tooltipTitle} >
                        <IconButton
                            variant="contained"
                            color="secondary"
                            onClick={action}
                            size="small"
                        >
                            {icon}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='الغاء' >
                        <IconButton
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </Alert>
        </Modal>
    );
}

export default AlertWithOptions;