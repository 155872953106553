import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useEffect } from "react";
import { authorizedAPIs } from "../API/axiosSetup";

export default function Search({
  academicYear,
  setUsers,
  setInSearchMod,
  setNumberOfStudent,
  numberOfStudent,
}) {
  const searchStudent = (text) => {
    setInSearchMod(!!text);
    if (text) {
      authorizedAPIs
        .get(`user/search/${text}/${academicYear}`)
        .then((res) => {
          setNumberOfStudent(res.data.result.length);
          setUsers(res.data.result);
        })
        .catch((err) => {});
    } else {
      setUsers([]);
      setNumberOfStudent(numberOfStudent);
    }
  };

  return (
    <div>
      <TextField
        fullWidth
        id="outlined-basic"
        label="ابحث عن طالب"
        variant="outlined"
        onChange={(e) => {
          searchStudent(e.target.value);
        }}
      />
    </div>
  );
}
