import React , { useState } from "react";
import TextField from "@mui/material/TextField";

export default function Password({value}) {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa-solid fa-eye-slash");

  const show = () => {
    type === "password" ? setType("text") : setType("password");
    icon === "fa-solid fa-eye"
      ? setIcon("fa-solid fa-eye-slash")
      : setIcon("fa-solid fa-eye");
  };

  return (
    <div >
     
      <TextField
          id="outlined-password-input"
          type={type}
          value={value}
          autoComplete="current-password"
          variant="standard"
          InputProps={{
                 disableUnderline: true,
                 style: {fontSize: 24}
               }}
             
        />
        <i onClick={show} className={icon}></i>
    </div>
  );
}
