import React from "react";
import AddExamAndExercise from "../../components/AddExamAndExercuse";

function AddExercise() {
  return (
    <div>
      <AddExamAndExercise
        type="exercises"
        examState={false}
        title={"اضافة تمرين"}
        navigatePath="/exercise/"
        
      />
    </div>
  );
}

export default AddExercise;
