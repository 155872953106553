



import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddCourseForm from '../../components/AddCourseForm';
import AddCourseParts from '../../components/AddCourseParts';
import Typography from "@mui/material/Typography";


export default function AddCourse() {
  const [value, setValue] = React.useState('1');
  const [courseId, setCourseId] = React.useState();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: "80%", margin: "auto", typography: 'body1' }}>
      <Typography style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}>
        اضافة كورس
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab style={{ fontSize: 24 }} label="البيانات الاساسية" value="1" />
            <Tab style={{ fontSize: 24 }} label=" فيديوهات اضافية للكورس " value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><AddCourseForm setCourseId={setCourseId} /></TabPanel>
        <TabPanel value="2"><AddCourseParts courseId={courseId}/></TabPanel>
      </TabContext>
    </Box>
  );
}