import React, { useState, useEffect, useCallback } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import UnauthorizedAlert from "../../components/UnauthorizedAlert";

const initialHeadCells = [
  {
    id: "fullName",
    numeric: false,
    label: "الاسم",
  },

  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "phoneNumber",
    numeric: true,
    label: "رقم التليفون",
  },
];

function PendingUsers() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [unauthorized, setUnauthorized] = useState();
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [numberOfStudent, setNumberOfStudent] = useState();

  const handleDelete = async (_id) => {
    return await authorizedAPIs.delete(`/user/delete/${_id}`).then(() => {
      dispatch(showAlert("تم حذف الطالب بنجاح", "success"));
    });
  };

  const getNewUsers = useCallback(() => {
    const skip = page * rowsPerPage;

    authorizedAPIs
      .get(`/user/getNewUsers?limit=${rowsPerPage}&skip=${skip}`)
      .then((res) => {
        console.log(res.data);
        setPendingUsers(res.data.result);
        setNumberOfStudent(res.data.numberOfStudent);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        err.response.data.message === "does not have this authority" &&
          setUnauthorized("لا تمتلك هذه الصلاحية");
        setIsLoading(false);
      });
  }, [page, rowsPerPage]);

  useEffect(() => {
    getNewUsers();
  }, []);

  const confirm = (users_ids) => {
    authorizedAPIs
      .put("/user/admin/confirmUsers", { users_ids })
      .then((res) => {
        getNewUsers();
        dispatch(showAlert("تم اضافة الطالب بنجاح", "success"));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      {unauthorized ? (
        <UnauthorizedAlert unauthorized={unauthorized} />
      ) : (
        <>
          <>
            <Typography
              style={{
                fontSize: "30px",
                color: "#010004",
                textAlign: "center",
              }}
            >
              قائمة الانتظار
            </Typography>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <EnhancedTable
                withEdit={false}
                withDelete
                manyAction={confirm}
                manyActionLabel={"add-to-students"}
                withConfirm
                handleDeleteAPI={handleDelete}
                initialRows={pendingUsers}
                headCells={initialHeadCells}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                page={page}
                setPage={setPage}
                count={numberOfStudent}
                path={"/Pending-Users/"}
              />
            )}
          </>
        </>
      )}
    </div>
  );
}

export default PendingUsers;
