import { Typography } from "@mui/material";
import React from "react";
import AllExamAndExercises from "../../components/allExamAndExercises";


const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "price",
    numeric: true,
    label: "السعر",
  },
];

function ExercisesWithSolutions() {


  return (
    <div>
       <Typography
            style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
          >
            التمارين بالاجابات
          </Typography>
     <AllExamAndExercises api={"/exercises/admin/getMany/"} initialHeadCells={initialHeadCells} path={"/exercises-solutions/"}/>

       
    </div>
  );
}

export default ExercisesWithSolutions;
