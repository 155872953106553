import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export default function Date({ title, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        name="startDate"
        label={title}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}
