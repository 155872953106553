import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import SelectionAcademicYears from "../components/selectionAcademicYears";
import SelectionUnit from "../components/selectionUnit";
import { authorizedAPIs } from "../API/axiosSetup";
import { showAlert } from "../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import getYouTubeID from "get-youtube-id";
import { useNavigate } from 'react-router-dom';


export default function AddCourseForm({setCourseId}) {
  const [isFree, setIsFree] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const academicYears = [
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];

  const [file, setFile] = useState();
  const [units, setUnits] = useState([]);

  const change = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChangeIsFree = (e) => {
    setIsFree(!isFree);
  };

  const changeUnit = async (e) => {
    await authorizedAPIs
      .get(`/unit/admin/getByAccadmicYear/${e.target.value}`)
      .then((res) => {
        setUnits([...res.data]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
    
      <Formik
        initialValues={{
          title: "",
          courseLink: "",
          courseAttachment: "",
          courseDescription: "",
          price: "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("الاسم مطلوب").max(255),
          courseLink: Yup.string().required("لينك الكورس مطلوب").max(255),
          courseAttachment: Yup.string()
            .max(255),
          courseDescription: Yup.string()
            .required("الوصف مطلوب")
            .max(255),
          price: Yup.number().required("السعر مطلوب").min(0),
          academicYear: Yup.mixed().required("المرحلة الدراسية مطلوبة"),
          unitId: Yup.mixed().required("الوحدة مطلوبة"),
        })}
        onSubmit={(values, { resetForm }) => {
          const {
            title,
            courseLink,
            courseAttachment,
            courseDescription,
            price,
            academicYear,
            unitId,

          } = values;

         


          if (courseLink.includes("drive")) {
            values.poster = courseLink;
          } else {
            const youtubeVideoId = getYouTubeID(courseLink);
            const poster = `https://i.ytimg.com/vi/${youtubeVideoId}/hq720.jpg`;
            values.poster = poster;
          }
          values.isFree = isFree;

          authorizedAPIs.post("/course/create", values)
            .then((res) => {
              console.log({ res });
              setCourseId(res.data._id)                
              dispatch(showAlert("تم اضافة الكورس بنجاح", "success"));
              resetForm();
              navigate("/course")

            })
            .catch((err) => {
              console.log(err);
              err.response.data.message === "does not have this authority" &&
              dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
            navigate("/course")
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: 600, margin: "auto" }}
          >
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="الاسم"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="title"
                value={values.title}
                variant="outlined"
                disabled={isSubmitting}

              />
            </Grid>


            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.courseLink && errors.courseLink)}
                fullWidth
                helperText={touched.courseLink && errors.courseLink}
                label="لينك الكورس"
                margin="normal"
                name="courseLink"
                onBlur={handleBlur}
                onChange={handleChange}
                type="courseLink"
                value={values.courseLink}
                variant="outlined"
                disabled={isSubmitting}

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.courseAttachment && errors.courseAttachment
                )}
                fullWidth
                helperText={touched.courseAttachment && errors.courseAttachment}
                label="لينك ملف"
                margin="normal"
                name="courseAttachment"
                onBlur={handleBlur}
                onChange={handleChange}
                type="courseAttachment"
                value={values.courseAttachment}
                variant="outlined"
                disabled={isSubmitting}

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                multiline
                maxRows={100}
                error={Boolean(
                  touched.courseDescription && errors.courseDescription
                )}
                fullWidth
                helperText={
                  touched.courseDescription && errors.courseDescription
                }
                label="الوصف"
                margin="normal"
                name="courseDescription"
                onBlur={handleBlur}
                onChange={handleChange}
                type="courseDescription"
                value={values.courseDescription}
                variant="outlined"
                disabled={isSubmitting}

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && errors.price}
                label="السعر"
                margin="normal"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.price}
                variant="outlined"
                disabled={isSubmitting}

              />
            </Grid>
            <FormControlLabel

                control={<Checkbox onChange={handleChangeIsFree} />}
                label="مجاني"
              />
              {isFree && (
                <Typography variant="h6" noWrap component="div" color="#1793b9">
                أصبح هذا المحتوى مجانا يستطيع أى  مستخدم رؤية هذا المحتوى
                </Typography>
              )}


            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              value={values.academicYear}
              error={Boolean(errors.academicYear)}
              helperText={errors.academicYear}
              changeUnit={changeUnit}
            />
            <SelectionUnit
              option={units}
              handleChange={handleChange}
              value={values.unitId}
              error={Boolean(errors.unitId)}
              helperText={errors.unitId}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
            اضافة كورس
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}