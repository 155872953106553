import React from "react";
import AddExamAndExercise from "../../components/AddExamAndExercuse";

function AddExam() {
  return (
    <div>
      <AddExamAndExercise
        examState={true}
        title={"اضافة أمتحان"}
        navigatePath="/exam/"
        type = "exam"
      />
    </div>
  );
}

export default AddExam;
