import React from 'react'
import OneExercise from '../exercise/oneExercise'

function OneExam() {
  return (
    <div>
        <OneExercise/>
    </div>
  )
}

export default OneExam