import React, { useState, useEffect, useCallback } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import { authorizedAPIs } from "../../API/axiosSetup";

import SelectionAcademicYears from "../../components/selectionAcademicYears";
import { Typography } from "@mui/material";
import UnauthorizedAlert from "../../components/UnauthorizedAlert";

const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "price",
    numeric: true,
    label: "السعر",
  },
];

function AddCourseToUsers() {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const rowsPerPageOptions = [ 5, 10, 25, 50, 100, 150, 200, 250, 300]
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [counter , setCounter] = useState();
  
  const academicYears = [
    { code: "All", label: "الكل" },
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];

  const [academicYear, seAcademicYear] = useState("All");
  const [unauthorized, setUnauthorized] = useState();

  const handleChange = (e) => {
    seAcademicYear(e.target.value);
  };

  const changeUnit = useCallback(() => {
    const skip = (page) * rowsPerPage;

    authorizedAPIs
      .get(`/course/admin/getMany?limit=${rowsPerPage}&skip=${skip}&academicYear=${academicYear}`)
      .then((res) => {
        setCourses(res.data.result);
        setCounter(res.data.counter)
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        err.response.data.message === "does not have this authority" &&
          setUnauthorized("لا تمتلك هذه الصلاحية");
      });
  }, [academicYear,rowsPerPage, page]);
  useEffect(() => {
    changeUnit();
  }, [academicYear,rowsPerPage, page]);

  return (
    <div>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        اضافة كورس الي طالب
      </Typography>
      {unauthorized ? (
        <UnauthorizedAlert unauthorized={unauthorized} />
      ) : (
        <>
            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              changeUnit={changeUnit}
              value={academicYear}
            />
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              <EnhancedTable
                initialRows={courses}
                headCells={initialHeadCells}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                page={page}
                setPage={setPage}
                count={counter}
                path={"/add-course-to-user/"}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AddCourseToUsers;
