import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PageTitle({ title }) {
  const { t } = useTranslation();
  return (
    <Typography
      style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
    >
      {t(title)}
    </Typography>
  );
}
