import { Typography } from "@mui/material";
import React from "react";
import AllExamAndExercises from "../../components/allExamAndExercises";

const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "price",
    numeric: true,
    label: "السعر",
  },
];

function DeleteExerciseFromUsers() {
  return (
    <div>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        حذف تمرين من طالب
      </Typography>
      <AllExamAndExercises
          addToUser={true}

        api={"/exercises/admin/getMany"}
        initialHeadCells={initialHeadCells}
        path={"/delete-exercise-from-user/"}
      />
    </div>
  );
}

export default DeleteExerciseFromUsers;
