import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AddCourseParts from '../../components/AddCourseParts';
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import EditCourseForm from '../../components/EditCourseForm';
import EditCourseParts from '../../components/EditCourseParts';



export default function EditCourse() {
  const [value, setValue] = React.useState('1');

  const { _id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: "80%", margin: "auto", typography: 'body1' }}>
      <Typography style={{ fontSize: "30px", color: "#010004", textAlign:"center"  }}>
          تعديل كورس
        </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab style={{ fontSize: 24 }} label="البيانات الاساسية" value="1" />
            <Tab style={{ fontSize: 24 }} label=" فيديوهات اضافية للكورس " value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><EditCourseForm _id ={_id }/></TabPanel>
        <TabPanel value="2"><EditCourseParts courseId ={_id }/></TabPanel>
      </TabContext>
    </Box>
  );
}