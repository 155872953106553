import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { CssBaseline, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_MY_BACKEND_HOST}`,
  timeout: 10000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          تسجيل الدخول
        </Typography>
      </Box>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
          .email("ادخل ايميل صحيح")
          .max(255)
          .required("يجب تدخيل الايميل"),
        password: Yup.string()
          .min(8, "كلمه السر يجب ان تكون اكثر من 8 حروف")
          .max(255)
          .required("يجب تدخيل كلمه السر"),
        })}
        onSubmit={async (values, { resetForm }) => {
          const { email, password } = values;
          api
            .post(`/authentication/admin/login`, {
              email,
              password,
            })
            .then((res) => {
              Cookies.set(process.env.REACT_APP_TOKEN_NAME, res.data.token, {
                expires: 30,
              });
              navigate("/");
              window.location.reload();

            })
            .catch((err) => {
              console.log(err.message);
              dispatch(showAlert("الايميل او الرقم السري غلط", "error"));
              resetForm();
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="الايميل"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                disabled={isSubmitting}
              
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="كلمه السر"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                disabled={isSubmitting}
              
              />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
            تسجيل دخول
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}
