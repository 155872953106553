import React , {useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import SelectionAcademicYears from "../../components/selectionAcademicYears";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

export default function AddUnit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const academicYears = [
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];

  return (
    <>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        اضافه وحدة
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: "",
          unitNumber: "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("الاسم مطلوب").max(255),
          unitNumber: Yup.number().required("رقم الوحدة مطلوب").min(0).max(100),
          academicYear: Yup.mixed().required("المرحلة الدراسية مطلوبة"),
        })}
        onSubmit={(values, { resetForm }) => {
          authorizedAPIs
            .post("/unit/create", values)
            .then((res) => {
              console.log({ res });
              dispatch(showAlert("تم اضافة الوحدة بنجاح", "success"));
              resetForm();
              navigate("/unit");
            })
            .catch((err) => {
              err.response.data.message === "does not have this authority" &&
              dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
              navigate("/unit");

              
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: 600, margin: "auto" }}
          >
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="الاسم"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="title"
                value={values.title}
                variant="outlined"
                disabled={isSubmitting}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.unitNumber && errors.unitNumber)}
                fullWidth
                helperText={touched.unitNumber && errors.unitNumber}
                label="رقم الوحده"
                margin="normal"
                name="unitNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.unitNumber}
                variant="outlined"
                disabled={isSubmitting}
              />
            </Grid>
            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              value={values.academicYear}
              error={Boolean(errors.academicYear)}
              helperText={errors.academicYear}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              اضافة وحده
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}
