import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../components/PageTitle";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { authorizedAPIs } from "../API/axiosSetup";

export default function Settings() {
  const { t } = useTranslation();
  const [setting, setSetting] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  //   const [unAuthorized, setUnAuthorized] = useState(true);

  useEffect(() => {
    if (isUpdating) return;
    setIsLoading(true);
    authorizedAPIs
      .get("/setting")
      .then((response) => {
        setSetting(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // setUnAuthorized(true);
      });
  }, [isUpdating]);

  const changeSetting = (path, body = {}) => {
    setIsUpdating(true);
    authorizedAPIs
      .put('/setting'+path, body)
      .then((response) => {
        setIsUpdating(false);
      })
      .catch((error) => {
        setIsUpdating(false);
      });
  };

  return (
    <Box>
      <PageTitle title="dashboard-settings"></PageTitle>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <FormGroup>
          <FormControlLabel
          
            onChange={() => {
              changeSetting("/manual-user-acceptance");
            }}
            control={<Switch checked={setting.manualUserAcceptance} />}
            label="القبول اليدوي للطلاب"
          />
        </FormGroup>
      )}
    </Box>
  );
}
