import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function SelectionUnit({
  option,
  handleChange,
  value = "",
  error,
  helperText,
  changeCourse
}) {
 

  return (
    <Box sx={{ minWidth: 120, margin: "20px 0" }}>
      <FormControl error={error} fullWidth>
        <InputLabel id="demo-simple-select-label">الوحدات</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={value}
          label="الوحدات"
          onChange={(e) => {
            handleChange(e);
            changeCourse && changeCourse(e);
          }}
          inputProps={{ name: "unitId", id: "unitId" }}
        >
          {option.map(({ title, _id }, index) => {
            return (
              <MenuItem key={index} value={_id}>
                {title}
              </MenuItem>
            );
          })}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
