import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import SelectionAcademicYears from "../../components/selectionAcademicYears";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

export default function EditUser() {
  const dispatch = useDispatch();
  const { _id } = useParams();
  const navigate = useNavigate();

  const academicYears = [
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];

  const [inputsData, setInputsData] = useState({});

  useEffect(() => {
    authorizedAPIs
      .get(`/user/getOne/${_id}`)
      .then((res) => {
        console.log({ res });
        setInputsData({ ...res.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        تعديل الطالب
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          fullName: inputsData.fullName,
          phoneNumber: inputsData.phoneNumber,
          parentPhoneNumber: inputsData.parentPhoneNumber,
          academicYear: inputsData.academicYear,
          email: inputsData.email,
          password: inputsData.password,
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string()
            .required("يجب تدخيل الاسم")
            .min(2, "too Short")
            .max(100, "Too Long"),
          email: Yup.string()
            .email("ادخل ايميل صحيح")
            .max(255)
            .required("يجب تدخيل الايميل"),
          password: Yup.string()
            .min(8, "كلمه السر يجب ان تكون اكثر من 8 حروف")
            .max(255)
            .required("يجب تدخيل كلمه السر"),

          phoneNumber: Yup.string().required("يجب تدخيل رقم التليفون"),
          parentPhoneNumber: Yup.string().required(
            "يجب تدخيل رقم تليفون ولي الامر"
          ),
        })}
        onSubmit={(values, { resetForm }) => {
          authorizedAPIs
            .put(`/user/edit_student/${_id}`, values)
            .then((res) => {
              console.log(res);
              dispatch(showAlert("تم تعديل الطالب بنجاح", "success"));
              resetForm();
              navigate("/users");
            })
            .catch((err) => {
              console.log(err.message);
              err.response.data.message === "does not have this authority" &&
                dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: 600, margin: "auto" }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={Boolean(touched.fullName && errors.fullName)}
                helperText={touched.fullName && errors.fullName}
                label="الاسم"
                margin="normal"
                name="fullName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="fullName"
                value={values.fullName}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label=" الايميل"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                fullWidth
                helperText={touched.phoneNumber && errors.phoneNumber}
                label="تليفون الطالب"
                margin="normal"
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.phoneNumber}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.parentPhoneNumber && errors.parentPhoneNumber
                )}
                fullWidth
                helperText={
                  touched.parentPhoneNumber && errors.parentPhoneNumber
                }
                label="تليفون ولي الامر"
                margin="normal"
                name="parentPhoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                value={values.parentPhoneNumber}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="الرقم السري"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                value={values.password}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              value={values.academicYear}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              تعديل الطالب
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}
