import { BrowserRouter } from "react-router-dom";
import Handleauthentication from "./components/Handleauthentication";
import React from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducers from "./Redux/reducers/index";
import ViewAlert from "./components/ViewAlert";
import "./utils/i18";

import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// STORE

const store = createStore(
  rootReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">
          <Handleauthentication />
          <ViewAlert />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
