import axios from "axios";
import Cookies from "js-cookie";

const authenticationAPIs = axios.create({
  baseURL: `${process.env.REACT_APP_MY_BACKEND_HOST}`,
  timeout: 3000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});


 
//#region /** Authorized APIs */ 

const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);

const authorizedAPIs = axios.create({
  baseURL: `${process.env.REACT_APP_MY_BACKEND_HOST}`,
  timeout: 3000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "authorization":  `123=${token}`,
  },
});


const FormDataAuthorizedAPIs = axios.create({
  baseURL: `${process.env.REACT_APP_MY_BACKEND_HOST}`,
  timeout: 3000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
    "authorization": `123=${token}`
  },
});

export { authenticationAPIs, authorizedAPIs ,FormDataAuthorizedAPIs};

//#endregion