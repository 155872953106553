import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import Date from "./date";
import SelectionAcademicYears from "./selectionAcademicYears";
import { authorizedAPIs } from "../API/axiosSetup";
import SelectionUnit from "./selectionUnit";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SelectionCourse from "./selectionCourse";
import Typography from "@mui/material/Typography";
import { showAlert } from "../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function EditExamAndExercise({
  examState,
  title,
  navigatePath,
  type
}) {
  const navigate = useNavigate();
  const [inputsData, setInputsData] = useState({});
  const [showFinalResult, setShowFinalResult] = useState(false);
  const [showRightAnswer, setShowRightAnswer] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const dispatch = useDispatch();
  const { _id } = useParams();
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue.valueOf());
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue.valueOf());
  };

  const handleChangeResult = (e) => {
    setShowFinalResult(!showFinalResult);
  };

  const handleChangeAnswer = (e) => {
    setShowRightAnswer(!showRightAnswer);
  };

  const handleChangeIsFree = (e) => {
    setIsFree(!isFree);
  };

  const academicYears = [
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];

  const [units, setUnits] = useState([]);
  const [courses, setCourses] = useState([]);

  const changeUnit = useCallback(
    (e) => {
      let academicYear = e ? e.target.value : inputsData.academicYear;
      authorizedAPIs
        .get(`/unit/admin/getByAccadmicYear/${academicYear}`)
        .then((res) => {
          setUnits([...res.data]);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    [inputsData]
  );

  console.log({ inputsData });

  const changeCourse = useCallback(
    (e) => {
      let unitId = e ? e.target.value : inputsData.unitId;
      authorizedAPIs
        .get(`/course/getMany/${unitId}`)
        .then((res) => {
          setCourses([...res.data]);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    [inputsData]
  );

  const getExerciseData = useCallback(() => {
    authorizedAPIs
      .get(`/${type}/admin/getOne/${_id}`)
      .then((res) => {
        setInputsData({ ...res.data });
        setIsFree(res.data.isFree);
        setStartDate(res.data.startDate);
        setEndDate(res.data.endDate);
        setShowFinalResult(res.data.showFinalResult);
        setShowRightAnswer(res.data.showRightAnswer);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    changeUnit();
    changeCourse();
    !inputsData.academicYear && getExerciseData();
  }, [inputsData]);

  return (
    <>
      <Typography variant="h4" style={{ textAlign: "center" }}>
        {title}
      </Typography>
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: inputsData.title,
          questionLink: inputsData.questionLink,
          price: inputsData.price,
          academicYear: inputsData.academicYear,
          unitId: inputsData.unitId,
          courseId: inputsData.courseId,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("الاسم مطلوب").max(255),
          questionLink: Yup.string().required("لينك الاسأله مطلوب").max(555),
          price: Yup.number().required("السعر مطلوب").min(0).max(100),
          academicYear: Yup.mixed().required("المرحلة الدراسية مطلوبه"),
          unitId: Yup.mixed().required("الوحدة مطلوبه"),
          courseId: Yup.mixed().required("الكورس مطلوب"),
        })}
        onSubmit={(values, { resetForm }) => {
          let data = {
            ...values,
            examState,
            showFinalResult,
            showRightAnswer,
            isFree,
            startDate,
            endDate,
          };
          console.log({ data });

          authorizedAPIs
            .put(`/${type}/edit/${_id}`, data)
            .then((res) => {
              console.log({ res });
              dispatch(showAlert("تم التعديل بنجاح", "success"));
              navigate(navigatePath);
            })
            .catch((err) => {
              console.log(err);
              err.response.data.message === "does not have this authority" &&
              dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
                
              navigate(navigatePath);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: 600, margin: "auto" }}
          >
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="الاسم"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="title"
                value={values.title}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              value={values.academicYear}
              error={Boolean(errors.academicYear)}
              helperText={errors.academicYear}
              changeUnit={changeUnit}
            />
            <SelectionUnit
              option={units}
              handleChange={handleChange}
              value={values.unitId}
              error={Boolean(errors.unitId)}
              helperText={errors.unitId}
              changeCourse={changeCourse}
            />
            <SelectionCourse
              option={courses}
              handleChange={handleChange}
              value={values.courseId}
              error={Boolean(errors.courseId)}
              helperText={errors.courseId}
            />

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && errors.price}
                label="السعر"
                margin="normal"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.price}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFree}
                  value={isFree}
                  onChange={handleChangeIsFree}
                />
              }
              label="مجاني"
            />
            {isFree && (
              <Typography variant="h6" noWrap component="div" color="#1793b9">
                أصبح هذا المحتوى مجانا يستطيع أى مستخدم رؤية هذا المحتوى
              </Typography>
            )}

            <TextField
              error={Boolean(touched.questionLink && errors.questionLink)}
              fullWidth
              helperText={touched.questionLink && errors.questionLink}
              label="لينك الاسأله"
              margin="normal"
              name="questionLink"
              onBlur={handleBlur}
              onChange={handleChange}
              type="string"
              value={values.questionLink}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {examState ? (
              <div style={{ display: "grid", gap: 30, marginTop: 20 }}>
                <Date
                  onChange={handleChangeStartDate}
                  value={startDate}
                  title="تاريخ البدء"
                />

                <Date
                  onChange={handleChangeEndDate}
                  value={endDate}
                  title="تاريخ الانتهاء"
                />
              </div>
            ) : (
              ""
            )}
           
            <Button
              onClick={() => {
                handleSubmit();
                console.log({ errors });
              }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              {title}
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}
