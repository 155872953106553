import React, { useEffect, useState, useCallback } from "react";
import { authorizedAPIs } from "../../API/axiosSetup";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import { Typography } from "@mui/material";

const initialHeadCells = [
    {
        id: "title",
        numeric: false,
        label: "اسم الكورس",
    },
    {
        id: "count",
        numeric: true,
        label: "عدد مرات دخول الطالب",
    },
    {
        id: "maxDisplayTimes",
        numeric: true,
        label: "الحد الاقصي لدخول الكورس",
    },


];

function UserCourses({ user_id }) {


    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300]
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [page, setPage] = useState(0);
    const [counter, setCounter] = useState();


    const getStudentInCourse = useCallback(() => {
        const skip = (page) * rowsPerPage;

        authorizedAPIs
            .get(`/user/studentCourses?user_id=${user_id}&limit=${rowsPerPage}&skip=${skip}`)
            .then((res) => {
                console.log(res.data);
                setCourses(res.data.courses);
                setCounter(res.data.totalNumber)
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }, [user_id, page, rowsPerPage]);

    useEffect(() => {
        getStudentInCourse();
    }, [user_id, page, rowsPerPage]);


    const handelChange = (course, courseAllowNumber) => {
        authorizedAPIs
            .put(`/user/addAllowCourseNumber/${user_id}`, {
                course_id: course._id,
                AllowNumber: courseAllowNumber
            })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }



    return (
        <div>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    <Typography
                        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
                    >
                        الكورسات الخاصة بالطالب
                    </Typography>
                    <EnhancedTable
                        withEdit={false}
                        withInput={true}
                        initialRows={courses}
                        headCells={initialHeadCells}
                        courses={courses}
                        path={"/course/"}
                        user_id={user_id}
                        onBlur={handelChange}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        rowsPerPageOptions={rowsPerPageOptions}
                        page={page}
                        setPage={setPage}
                        count={counter}
                        inputKey="courseAllowNumber"
                    />
                </>
            )}
        </div>
    );
}

export default UserCourses;
