import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";

const initialHeadCells = [
  {
    id: "fullName",
    numeric: false,
    label: "الاسم",
  },


  {
    id: "phoneNumber",
    numeric: true,
    label: "رقم التليفون",
  },
];

function Assistants() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [assistants, setAssistants] = useState([]);
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300]
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [numberOfAssistants, setNumberOfAssistants] = useState()


  const handleDelete = async (_id) => {
    return await authorizedAPIs.delete(`/user/delete/${_id}`).then(() => {
      dispatch(showAlert("تم حذف المساعد بنجاح", "success"));
    });
  };

  const handleBlock = async (_id) => {
    console.log(_id);
    // return await authorizedAPIs.delete(`/user/activate_deactivate_user/${_id}`).then(() => {
    //   dispatch(showAlert("تم حظر المساعد بنجاح", "success"));
    // });
  };

  useEffect(() => {
    const skip = (page) * rowsPerPage;

    authorizedAPIs
      .get(`/user/admin/get_all_assistants?limit=${rowsPerPage}&skip=${skip}`)
      .then((res) => {
        console.log({ res });
        setAssistants(res.data.assistants);
        setNumberOfAssistants(res.data.numberOfAssistants)
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  }, [rowsPerPage, page]);

  return (
    <div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Typography
            style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
          >
            المساعدين
          </Typography>
          <EnhancedTable
            withBlock
            withEdit
            withDelete
            handleDeleteAPI={handleDelete}
            // handleblockAPI={handleBlock}
            initialRows={assistants}
            headCells={initialHeadCells}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            setPage={setPage}
            path={"/assistant/"}
            count={numberOfAssistants}
          />
        </>
      )}
    </div>
  );
}

export default Assistants;
