import { Typography } from "@mui/material";
import React from "react";

import AllExamAndExercises from "../../components/allExamAndExercises";

const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "startDate",
    numeric: false,
    label: "تاريخ البدء",
  },
  {
    id: "endDate",
    numeric: false,
    label: "تاريخ الانتهاء",
  },
  {
    id: "price",
    numeric: true,
    label: "السعر",
  },
];

function Exams({ authority }) {
  return (
    <div>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        الامتحانات
      </Typography>
      <AllExamAndExercises
        authority={authority}
        addToUser={false}
        type="exam"
        api={"/exercises/admin/getManyExam"}
        initialHeadCells={initialHeadCells}
        path={"/exam/"}
        title="اضافة امتحان"
      />
    </div>
  );
}

export default Exams;
