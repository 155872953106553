import React, { useEffect, useState } from "react";
import { authorizedAPIs } from "../API/axiosSetup";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import academicYears from "../API/academicYears.json";
import Password from "../components/Password";
import UserCourses from "./users/UserCourses";

function OneUser() {
  const { _id } = useParams();
  const [oneUser, setOneUser] = useState({});

  useEffect(() => {
    authorizedAPIs
      .get(`/user/getOne/${_id}`)
      .then((res) => {
        console.log({ res });
        setOneUser(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [_id]);
  return (
    <>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        معلومات الطالب
      </Typography>
      <Box
        sx={{
          boxShadow: 3,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          p: "20px 30px",
          m: 1,
          borderRadius: 2,
          margin: "40px 9px",
          display: "grid",
          gap: "25px",
        }}
      >
        <Typography variant="h4" component="h2">
          اسم الطالب : <span>{oneUser.fullName}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          الايميل : <span>{oneUser.email}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          رقم تليفون الطالب : <span>{oneUser.phoneNumber}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          رقم تليفون ولي الامر : <span>{oneUser.parentPhoneNumber}</span>
        </Typography>
        {oneUser.authority !== "admin" && (
          <Typography variant="h4" component="h2">
            المرحلة الدراسية :{" "}
            <span>{academicYears[oneUser.academicYear]}</span>
          </Typography>
        )}
        <Typography
          variant="h4"
          component="h2"
          sx={{ display: "flex", gap: "0 20px" }}
        >
          كلمه السر :<Password value={oneUser.password} />
        </Typography>
        <Typography variant="h4" component="h2">
          الصلاحية : <span>{oneUser.authority}</span>
        </Typography>
      </Box>
      {oneUser.userState && <UserCourses user_id={oneUser._id} />}
    </>
  );
}

export default OneUser;
