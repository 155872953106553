import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import { authorizedAPIs } from "../../API/axiosSetup";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";

export default function AddAssistants() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  return (
    <>
      <Formik
        initialValues={{
          fullName: "",
          email: "",
          password: "",
          phoneNumber: "",
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string()
            .required("يجب تدخيل الاسم")
            .min(2, "too Short")
            .max(100, "Too Long"),
          email: Yup.string()
            .email("ادخل ايميل صحيح")
            .max(255)
            .required("يجب تدخيل الايميل"),
          password: Yup.string()
            .min(8, "كلمه السر يجب ان تكون اكثر من 8 حروف")
            .max(255)
            .required("يجب تدخيل كلمه السر"),

          phoneNumber: Yup.string().required("يجب تدخيل رقم التليفون"),
        })}
        onSubmit={(values) => {
          authorizedAPIs
            .post("/user/admin/crete_assistant", values)
            .then((res) => {
              console.log({ res });
              dispatch(showAlert("تم اضافة المساعد بنجاح", "success"));
              navigate("/assistant");
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: 600, margin: "auto" }}
          >
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.fullName && errors.fullName)}
                fullWidth
                helperText={touched.fullName && errors.fullName}
                label="الاسم"
                margin="normal"
                name="fullName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="fullName"
                value={values.fullName}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label=" الايميل"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                fullWidth
                helperText={touched.phoneNumber && errors.phoneNumber}
                label="رقم التليفون"
                margin="normal"
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.phoneNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="الرقم السري"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>

            <Button type="submit" fullWidth variant="contained" color="primary">
              اضافة مساعد
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}
