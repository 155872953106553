import { Typography } from "@mui/material";
import React from "react";
import AllExamAndExercises from "../../components/allExamAndExercises";

const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "startDate",
    numeric: false,
    label: "تاريخ البدء",
  },
  {
    id: "endDate",
    numeric: false,
    label: "تاريخ الانتهاء",
  },
  {
    id: "price",
    numeric: true,
    label: "السعر",
  },
];

function AddExamToUsers() {
  return (
    <div>
      <>
        <Typography
          style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
        >
          اضافة أمتحان الى طالب
        </Typography>
        <AllExamAndExercises
          addToUser={true}
          type="addToUser"
          api={"/exercises/admin/getManyExam"}
          initialHeadCells={initialHeadCells}
          path={"/add-exam-to-user/"}
        />
      </>
    </div>
  );
}

export default AddExamToUsers;
