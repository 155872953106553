import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { authorizedAPIs } from "../API/axiosSetup";
import { useParams } from "react-router-dom";
import academicYears from "../API/academicYears.json";
import Questions from "../pages/exercise/Questions";


function UserSolutionForExamAndExercise() {
  const { _id } = useParams();
  const [user, setUser] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);

  useEffect(() => {
    authorizedAPIs
      .get(`/solution/getOne/${_id}`)
      .then((res) => {
        console.log({ res });
        setUser(res.data);
        setAllQuestions([...res.data.questions]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [_id]);

  return (
    <>
      <Box
        sx={{
          boxShadow: 3,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
            p: "20px 30px",
          m: 1,
          borderRadius: 2,
          margin: "40px 9px",
        }}
      >
         <Typography variant="h4" component="h2">
          اسم الطالب: <span>{user.fullName}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          الاسم: <span>{user.title}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          المرحلة الدراسية: <span>{academicYears[user.academicYear]}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          الدرجه الكلية: <span>{user.totalDegree}</span>
        </Typography>
        <Typography variant="h4" component="h2">
           درجه الطالب: <span>{user.degree}</span>
        </Typography>
      
      </Box>
      <Questions allQuestions={allQuestions} />
    </>
  );
}

export default UserSolutionForExamAndExercise;
