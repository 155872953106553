import React from "react";
import getYouTubeID from "get-youtube-id";

function PDFAndVideosHandler({ link ,attachment}) {
  return (
    <div>
      {link&&link.includes("drive") || attachment&&attachment.includes("drive") ? (
        <iframe
          src={link ||attachment}
          height="800"
          width="95%"
          style={{ display: "block", margin: "auto", marginTop: 20 }}
          allow="autoplay"
        ></iframe>
      ) : (
        <iframe
          width="100%"
          height="600px"
          src={"https://www.youtube.com/embed/" + getYouTubeID(link || attachment)}
          frameborder="0"
          allowfullscreen
        ></iframe>
      )}
    </div>
  );
}

export default PDFAndVideosHandler;
