import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "./Table/table";
import { authorizedAPIs } from "../API/axiosSetup";
import { showAlert } from "../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import UnauthorizedAlert from "./UnauthorizedAlert";
import Search from "./Search";

const initialHeadCells = [
  {
    id: "fullName",
    numeric: false,
    label: "الاسم",
  },

  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "phoneNumber",
    numeric: true,
    label: "رقم التليفون",
  },
];

function UsersTable({ authority, academicYear }) {
  const dispatch = useDispatch();
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfStudent, setNumberOfStudent] = useState();
  const [users, setUsers] = useState([]);
  const [unauthorized, setUnauthorized] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [inSearchMode, setInSearchMode] = useState(false);

  const handleDelete = async (_id) => {
    return await authorizedAPIs.delete(`/user/delete/${_id}`).then(() => {
      dispatch(showAlert("تم حذف الطالب بنجاح", "success"));
    });
  };

  useEffect(() => {
    const skip = page * rowsPerPage;
    if (!inSearchMode) {
      authorizedAPIs
        .get(
          `/user/getMany?limit=${rowsPerPage}&skip=${skip}&academicYear=${academicYear}`
        )
        .then((res) => {
          setUsers(res.data.result);
          setNumberOfStudent(res.data.numberOfStudent);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          err.response.data.message === "does not have this authority" &&
            setUnauthorized("لا تمتلك هذه الصلاحية");
          setIsLoading(false);
        });
    }

    authority !== "admin" &&
      authorizedAPIs.get(`user/getAuthorityByAuthName/user`).then((res) => {
        setIsEdit(res.data.subAuthorizes.user.edit);
        setIsDelete(res.data.subAuthorizes.user.delete);
      });
  }, [rowsPerPage, page, inSearchMode]);

  return (
    <div>
      {unauthorized ? (
        <UnauthorizedAlert unauthorized={unauthorized} />
      ) : (
        <>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              <Search
                academicYear={academicYear}
                setUsers={setUsers}
                setInSearchMod={setInSearchMode}
                setNumberOfStudent={setNumberOfStudent}
                numberOfStudent={numberOfStudent}
              />
              <EnhancedTable
                withDelete={isDelete ? true : false}
                withEdit={isEdit ? true : false}
                handleDeleteAPI={handleDelete}
                initialRows={users}
                headCells={initialHeadCells}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                page={page}
                setPage={setPage}
                count={numberOfStudent}
                path={"/users/"}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UsersTable;
