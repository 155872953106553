import React, { useEffect, useState } from "react";
import { authorizedAPIs } from "../../API/axiosSetup";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Password from "../../components/Password";

import AddAuthorization from "./AddAuthorization";

function OneAssistant() {
  const { _id } = useParams();
  const [oneUser, setOneUser] = useState({});
  const [subAuthorizes, setSubAuthorizes] = useState({});
  useEffect(() => {
    authorizedAPIs
      .get(`/user/getOne/${_id}`)
      .then((res) => {
        setOneUser(res.data);
        setSubAuthorizes({ ...res.data.subAuthorizes });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [_id]);

  return (
    <>
      <Typography
        style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
      >
        معلومات المساعد
      </Typography>
      <Box
        sx={{
          boxShadow: 3,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          p: "20px 30px",
          m: 1,
          borderRadius: 2,
          margin: "40px 9px",
          display: "grid",
          gap: "25px",
        }}
      >
        <Typography variant="h4" component="h2">
          الاسم : <span>{oneUser.fullName}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          الايميل : <span>{oneUser.email}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          رقم التليفون : <span>{oneUser.phoneNumber}</span>
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          sx={{ display: "flex", gap: "0 20px" }}
        >
          كلمه السر :<Password value={oneUser.password} />
        </Typography>
        <Typography variant="h4" component="h2">
          الصلاحية : <span>{oneUser.authority}</span>
        </Typography>
      </Box>

      <AddAuthorization
        subAuthorizes={subAuthorizes}
        _id={oneUser._id}
        authority="user"
        title="صلاحيات الطالب"
        showAddToStudent={false}
        
      />
       <AddAuthorization
        subAuthorizes={subAuthorizes}
        _id={oneUser._id}
        authority="unit"
        title="صلاحيات الوحدة"
        showAddToStudent={false}
      />
      <AddAuthorization
        subAuthorizes={subAuthorizes}
        _id={oneUser._id}
        authority="course"
        title="صلاحيات الكورس"
        showAddToStudent={true}
      />
      <AddAuthorization
        subAuthorizes={subAuthorizes}
        _id={oneUser._id}
        authority="exam"
        title="صلاحيات الامتحان"
        showAddToStudent={true}
      />
      <AddAuthorization
        subAuthorizes={subAuthorizes}
        _id={oneUser._id}
        authority="exercises"
        title="صلاحيات التمرين"
        showAddToStudent={true}
      />
     
    </>
  );
}

export default OneAssistant;
