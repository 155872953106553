import React from 'react'
import EditExamAndExercise from '../../components/EditExamAndExercise'

function EditExercise() {
  return (
    <div>
        <EditExamAndExercise type = "exercises"  examState={false} title={"تعديل التمرين"} navigatePath="/exercise/"/>
    </div>
  )
}

export default EditExercise