import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import UnauthorizedAlert from "../../components/UnauthorizedAlert";
import { useNavigate } from "react-router-dom";

const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },

  {
    id: "unitNumber",
    numeric: true,
    label: "رقم الوحدة",
  },
];

function Units({ authority }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [unauthorized, setUnauthorized] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [counter, setCounter] = useState();

  useEffect(() => {
    const skip = page * rowsPerPage;

    authorizedAPIs
      .get(`/unit/admin/getMany?limit=${rowsPerPage}&skip=${skip}`)
      .then((res) => {
        console.log(res.data);
        setUnits(res.data.result);
        setCounter(res.data.counter);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        err.response.data.message === "does not have this authority" &&
          setUnauthorized("لا تمتلك هذه الصلاحية");
        setIsLoading(false);
      });

    authority !== "admin" &&
      authorizedAPIs.get(`user/getAuthorityByAuthName/unit`).then((res) => {
        setIsEdit(res.data.subAuthorizes.unit.edit);
        setIsDelete(res.data.subAuthorizes.unit.delete);
      });
  }, [rowsPerPage, page]);

  const handleDelete = async (_id) => {
    return await authorizedAPIs.delete(`/unit/delete/${_id}`).then(() => {
      dispatch(showAlert("تم حذف الوحدة بنجاح", "success")).catch((err) =>
        console.log({ err })
      );
    });
  };

  const addUnit = () => {
    authority === "admin"
      ? navigate("/unit/create")
      : authorizedAPIs.get(`user/getAuthorityByAuthName/unit`).then((res) => {
          res.data.subAuthorizes.unit.create
            ? navigate("/unit/create")
            : dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
        });
  };

  return (
    <div>
      <Box textAlign="center">
        <Typography
          style={{ fontSize: "30px", color: "#010004", textAlign: "center" }}
          >
          الوحدات
        </Typography>
        <Button
          style={{ height: "45px", fontSize: "18px" }}
          variant="contained"
          onClick={addUnit}
        >
          اضافة وحدة
        </Button>
      </Box>
      {unauthorized ? (
        <UnauthorizedAlert unauthorized={unauthorized} />
      ) : (
        <>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <EnhancedTable
              withDelete={isDelete ? true : false}
              withEdit={isEdit ? true : false}
              handleDeleteAPI={handleDelete}
              initialRows={units}
              headCells={initialHeadCells}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              page={page}
              setPage={setPage}
              count={counter}
              path={"/unit/"}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Units;
