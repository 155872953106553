import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import LogIn from "./LogIn";
import React from 'react';
// import ForgetPassword from "./ForgetPassword";
// import ChangePassword from "./ChangePassword";

const theme = createTheme();

export default function AuthenticationRoutes() {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Routes>
          <Route exact path="/" element={<LogIn />} />
          <Route path='*' element={<LogIn />} />
        </Routes>
      </Container>

    </ThemeProvider>
  );
}
