import React from "react";
import Alert from "@mui/material/Alert";

function UnauthorizedAlert({ unauthorized }) {
  return (
    <div>
      <Alert
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: 24,
          alignItems: "center",
        }}
        severity="info"
      >
        {unauthorized}
      </Alert>
    </div>
  );
}

export default UnauthorizedAlert;
