import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function SelectionAcademicYears({
  option,
  handleChange,
  value = "",
  error,
  helperText,
  changeUnit,
}) {
  return (
    <Box sx={{ margin: "20px 0" }}>
      <FormControl error={error} fullWidth >
        <InputLabel id="demo-simple-select-label">المرحلة الدراسية</InputLabel>
        <Select
          style={{ height: "45px" }}
          fullWidth
          labelId="demo-simple-select-label"
          value={value}
          label="المرحلة الدراسية"
          onChange={(e) => {
            handleChange(e);
            changeUnit && changeUnit(e);
          }}
          inputProps={{ name: "academicYear", id: "academicYear" }}
        >
          {option.map(({ code, label }, index) => {
            return (
              <MenuItem key={index} value={code} style={{ direction: "rtl" }}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
