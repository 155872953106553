import React, { Component } from "react";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { authorizedAPIs } from "../../API/axiosSetup";

class AddAuthorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      delete: false,
      create: false,
      get: false,
      addToStudent: false,
      didUpdate: false,
    };

    this.changeState = this.changeState.bind(this);
  }

  componentDidUpdate() {
    if (this.state.didUpdate) return;
    const { subAuthorizes, authority } = this.props;
    console.log(this.props);

    this.setState({
      edit: subAuthorizes[authority]?.edit,
      delete: subAuthorizes[authority]?.delete,
      create: subAuthorizes[authority]?.create,
      get: subAuthorizes[authority]?.get,
      addToStudent: subAuthorizes[authority]?.addToStudent,
      didUpdate: true,
    });
  }

  changeState = (key) => {
    this.setState((state) => ({ [key]: !state[key] }));
    authorizedAPIs
      .put(`/user/change_sub_authority/${this.props._id}`, {
        firstKey: this.props.authority,
        secondKey: key,
        value: !this.state[key],
      })
      .then((res) => {
        console.log({ res });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  render() {
    const {showAddToStudent } = this.props
    return (
      <div>
        <div style={{ display: "grid" }}>
          <Typography
            style={{
              fontSize: "26px",
              color: "green",
              backgroundColor: "#e0e9f3",
              textAlign: "center",
              marginTop: 10,
            }}
          >
            {this.props.title}
          </Typography>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              width: "80%",
              margin: " 0 auto 20px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    this.changeState("create");
                  }}
                  checked={this.state.create}
                  value={this.state.create}
                />
              }
              label={this.props.authority === "user" ? "قبول طالب" : "اضافة"}
            />

            {showAddToStudent && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      this.changeState("addToStudent");
                    }}
                    checked={this.state.addToStudent}
                    value={this.state.addToStudent}
                  />
                }
                label={
                  this.props.authority === "course"
                    ? "اضافة او حذف كورس الي طالب"
                    : this.props.authority === "exam"
                    ? "اضافة او حذف امتحان الي طالب"
                    : this.props.authority === "exercises" &&
                      "اضافة او حذف تمرين الي طالب"
                }
              />
            )}
           
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    this.changeState("delete");
                  }}
                  checked={this.state.delete}
                  value={this.state.delete}
                />
              }
              label="حذف "
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    this.changeState("edit");
                  }}
                  checked={this.state.edit}
                  value={this.state.edit}
                />
              }
              label=" تعديل "
            />
           { this.props.authority !== "unit" && <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    this.changeState("get");
                  }}
                  checked={this.state.get}
                  value={this.state.get}
                />
              }
              label="عرض الكل "
            />}
          </div>
        </div>
      </div>
    );
  }
}

export default AddAuthorization;
