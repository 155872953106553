import React from 'react'
import EditExamAndExercise from '../../components/EditExamAndExercise'

function EditExam() {
  return (
    <div>
        <EditExamAndExercise type = "exam"  examState={true} title={"تعديل الامتحان"}  navigatePath="/exam/"/>
    </div>
  )
}

export default EditExam