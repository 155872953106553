import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const EnhancedTableToolbar = ({
  selected,
  manyActionLabel = "submit",
  manyAction = () => { },
}) => {

  const { t } = useTranslation();


  const numSelected = selected.length;

  console.log({ numSelected });

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {t()}
        </Typography>
      )}
      {numSelected > 0 && (
        manyAction && (
          <Button
            variant="contained"
            onClick={() => {
              manyAction(selected);
              selected.length = 0
            }}
            sx={{ height: 45, width: 200 }}
          >
            {t(manyActionLabel)}
          </Button>
        )
      )
      }
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
