import { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconButton, InputBase, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { Link } from "react-router-dom";
import React from "react";
import academicYears from "../../API/academicYears.json";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function CustomTableRow({
  children,
  row,
  cellsInTheRightOrder,
  handleDelete,
  path,
  index,
  withEdit,
  withDelete,
  withBlock,
  withInput,
  inputKey,
  onBlur,
  courses,
  onChange,
  user_id
}) {



  const [rowValues, setRowValues] = useState({});
  const dispatch = useDispatch();
  const [userState, setUserState] = useState(row.userState);
  const [inputValue, setInputValue] = useState()
  useEffect(() => {
    if (academicYears[row.academicYear])
      row.academicYear = academicYears[row.academicYear];

    row.isFree && (row.price = "مجاني");

    setRowValues({ ...row });
    setInputValue(row[inputKey])
  }, [row]);

  const handleBlock = async (_id) => {
    const body = { userState: !userState };

    return await authorizedAPIs
      .put(`/user/activate_deactivate_user/${_id}`, body)
      .then((res) => {
        userState === false
          ? dispatch(showAlert("تم فك حظر المساعد بنجاح", "success"))
          : dispatch(showAlert("تم حظر المساعد بنجاح", "success"));
        setUserState(!userState);
      });
  };

  useEffect(() => { }, [userState]);

  const handelChange = (number, _id) => {
    console.log(number);

    var courseAllowNumber = number.target.value
    console.log(courseAllowNumber);
    authorizedAPIs
      .put(`/user/addAllowCourseNumber/${user_id}`, {
        course_id: _id,
        AllowNumber: courseAllowNumber
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <TableRow>
      {children}

      {cellsInTheRightOrder.map((key) => (
        <TableCell
          sx={{ padding: "20px" }}
          padding="none"
          key={key.id}
          id={key.id}
          style={{ textDecoration: "none", fontSize: 20 }}
          component={path === "/unit/" ? "div" : Link}
          to={path + row._id}
        >
          {rowValues[key.id]}

        </TableCell>

      ))}
      <TableCell padding="none" sx={{ paddingLeft: "10px" }}>

        {withInput && <TextField
          style={{ paddingLeft: "10px" }}
          type="string"
          variant="outlined"
          name={inputKey}
          value={inputValue}
          onBlur={() => onBlur(rowValues, inputValue)}
          onChange={(event) => {
            const value = event.target.value
            setInputValue(value)
            onChange && onChange(rowValues, inputValue)
          }}
        />}
        {withEdit && (
          <IconButton
            component={Link}
            to={path + "edit/" + row._id}
            color="primary"
          >
            <EditIcon />
          </IconButton>
        )}
        {withDelete && (
          <IconButton onClick={() => handleDelete(index)} color="error">
            <DeleteIcon />
          </IconButton>
        )}
        {withBlock && (
          <IconButton
            onClick={() => handleBlock(rowValues._id)}
            color="primary"
          >
            {userState === false ? <NoAccountsIcon /> : <AccountCircleIcon />}
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
