import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import YouTubeIcon from "@mui/icons-material/YouTube";
import QuizIcon from "@mui/icons-material/Quiz";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import { authorizedAPIs } from "../API/axiosSetup";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function SideNaveLink({ text, to, IconComponent }) {
  return (
    <ListItem button component={Link} to={to}>
      <ListItemText
        primary={text}
        sx={{
          display: "flex",
          justifyContent: "end",
          marginRight: "10px",
        }}
      />

      <ListItemIcon sx={{ minWidth: "0" }}>
        <IconComponent />
      </ListItemIcon>
    </ListItem>
  );
}

export default function PersistentDrawerRight(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [addCourseToStudent, setAddCourseToStudent] = useState(true);
  const [addExercisesToStudent, setAddExercisesToStudent] = useState(true);
  const [addExamToStudent, setAddExamToStudent] = useState(true);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.authority !== "admin" &&
      authorizedAPIs.get(`user/getAuthorityByAuthName/course`).then((res) => {
        setAddCourseToStudent(res.data.subAuthorizes.course.addToStudent);
      });
    props.authority !== "admin" &&
      authorizedAPIs
        .get(`user/getAuthorityByAuthName/exercises`)
        .then((res) => {
          setAddExercisesToStudent(
            res.data.subAuthorizes.exercises.addToStudent
          );
        });
    props.authority !== "admin" &&
      authorizedAPIs.get(`user/getAuthorityByAuthName/exam`).then((res) => {
        setAddExamToStudent(res.data.subAuthorizes.exam.addToStudent);
      });
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, textAlign: "center" }}
            component="div"
          >
            لوحه تحكم
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <div dir="rtl">
              <DrawerHeader />
              {props.children}
            </div>
          </ThemeProvider>
        </CacheProvider>
      </Main>
      <Drawer
        id="drawer"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <SideNaveLink to="/" text="الرئيسية" IconComponent={HomeIcon} />
          <SideNaveLink to="/unit" text="الوحدات" IconComponent={AdUnitsIcon} />
          <SideNaveLink
            to="/course"
            text="الكورسات"
            IconComponent={YouTubeIcon}
          />
          <SideNaveLink
            to="/exercise"
            text="التمارين"
            IconComponent={QuizIcon}
          />

          <SideNaveLink to="/exam" text="الامتحانات" IconComponent={QuizIcon} />

          <SideNaveLink to="/users" text="الطلبة" IconComponent={PeopleIcon} />
          <SideNaveLink
            to="/Pending-Users"
            text="قائمه الانتظار"
            IconComponent={GroupAddIcon}
          />
          {addCourseToStudent && (
            <>
              <SideNaveLink
                to="/add-course-to-user"
                text="اضافة كورس الي طالب"
                IconComponent={AddCircleIcon}
              />
              <SideNaveLink
                to="/delete-course-from-user"
                text="حذف كورس من طالب"
                IconComponent={DeleteIcon}
              />
            </>
          )}

          {addExercisesToStudent && (
            <>
              <SideNaveLink
                to="/add-exercise-to-user"
                text="اضافة تمرين الي طالب"
                IconComponent={AddCircleIcon}
              />
              <SideNaveLink
                to="/delete-exercise-from-user"
                text="حذف تمرين من طالب"
                IconComponent={DeleteIcon}
              />
            </>
          )}

          {addExamToStudent && (
            <>
              <SideNaveLink
                to="/add-exam-to-user"
                text="اضافة امتحان الي طالب"
                IconComponent={AddCircleIcon}
              />
              <SideNaveLink
                to="/delete-exam-from-user"
                text="حذف امتحان من طالب"
                IconComponent={DeleteIcon}
              />
            </>
          )}

          {props.authority === "admin" && (
            <>
              <SideNaveLink
                to="/add-assistant"
                text="اضافة مساعد"
                IconComponent={PersonAddIcon}
              />
              <SideNaveLink
                to="/assistant"
                text=" المساعدين"
                IconComponent={GroupIcon}
              />
            </>
          )}
          {props.authority === "admin" && (
            <>
              <SideNaveLink
                to="/settings"
                text={t(`settings`)}
                IconComponent={SettingsSuggestIcon}
              />
            </>
          )}
          <ListItem
            component={Button}
            onClick={() => {
              Cookies.remove(process.env.REACT_APP_TOKEN_NAME);
              navigate("/");
              window.location.reload();
            }}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText
              style={{ color: "red", textAlign: "end", marginRight: "10px" }}
              primary={"تسجيل الخروج"}
            />
            <LogoutIcon style={{ color: "red" }} />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
