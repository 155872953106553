import React, { useEffect, useState, useCallback } from "react";
import { authorizedAPIs } from "../API/axiosSetup";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "./Table/table";
import { Typography } from "@mui/material";

const initialHeadCells = [
  {
    id: "fullName",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "email",
    numeric: false,
    label: "الايميل",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
];

function UsersInCourse({ _id }) {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300]
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [counter, setCounter] = useState();

  const getStudentInCourse = useCallback(() => {
    const skip = (page) * rowsPerPage;

    authorizedAPIs
      .get(`/course/admin/getStudentInCourse/${_id}?limit=${rowsPerPage}&skip=${skip}`)
      .then((res) => {
        console.log(res.data);
        setUsers(res.data.result);
        setCounter(res.data.count)
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [_id,page, rowsPerPage]);

  useEffect(() => {
    getStudentInCourse();
  }, [_id,page, rowsPerPage]);

  const deleteFromCourse = (users_ids) => {
    authorizedAPIs
      .put(`/course/admin/disallowCourse/${_id}`, { users_ids })
      .then((res) => {
        getStudentInCourse();
        dispatch(showAlert("تم حذف الكورس بنجاح", "success"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Typography
            style={{
              fontSize: "30px",
              color: "#010004",
              textAlign: "center",
              marginTop: 150,
            }}
          >
            الطلبة المسجليين في هذا الكورس
          </Typography>
          <EnhancedTable
            withEdit={false}
            manyAction={deleteFromCourse}
            manyActionLabel={"delete-from-course"}
            initialRows={users}
            headCells={initialHeadCells}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            setPage={setPage}
            count={counter}
            path={"/users/"}
          />
        </>
      )}
    </div>
  );
}

export default UsersInCourse;
