import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import * as Yup from "yup";
import { authorizedAPIs } from "../API/axiosSetup";
import { useEffect, useState } from "react";
import { showAlert } from "../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import SelectionAcademicYears from "../components/selectionAcademicYears";
import SelectionUnit from "../components/selectionUnit";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import getYouTubeID from "get-youtube-id";
import { useNavigate } from 'react-router-dom';


export default function EditCourseForm({_id }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [inputsData, setInputsData] = useState({});
  const [isFree, setIsFree] = useState(false);

  const [units, setUnits] = useState([]);

  const handleChangeIsFree = (e) => {
    setIsFree(!isFree);
  };

  const academicYears = [
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];



  const changeUnit = useCallback(
    (e) => {
      let academicYear = e ? e.target.value : inputsData.academicYear;
      authorizedAPIs
        .get(`/unit/admin/getByAccadmicYear/${academicYear}`)
        .then((res) => {
          setUnits([...res.data]);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    [inputsData]
  );
  const getCourseData = useCallback(() => {
    authorizedAPIs
      .get(`/course/admin/getOne/${_id}`)
      .then((res) => {
        console.log({ res });
        setInputsData({ ...res.data });
        setIsFree(res.data.isFree);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    changeUnit();
    !inputsData.academicYear && getCourseData();
  }, [inputsData]);

  const readURL = (input) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        console.log(e);
        document
          .getElementById("imagePoster")
          .setAttribute("src", e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  
  return (
    <>
     
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: inputsData.title,
          poster: inputsData.poster,
          courseLink: inputsData.courseLink,
          courseAttachment: inputsData.courseAttachment,
          courseDescription: inputsData.courseDescription,
          price: inputsData.price,
          academicYear: inputsData.academicYear,
          unitId: inputsData.unitId,
          maxDisplayTimes:inputsData.maxDisplayTimes

        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("الاسم مطلوب").max(255),
          courseLink: Yup.string().required("لينك الكورس مطلوب").max(255),
          courseAttachment: Yup.string()
            .max(255),
          courseDescription: Yup.string()
            .required("الوصف مطلوب")
            .max(255),
          price: Yup.number().required("السعر مطلوب").min(0),
          academicYear: Yup.mixed().required("المرحلة الدراسية مطلوبة"),
          unitId: Yup.mixed().required("الوحدة مطلوبة"),
          maxDisplayTimes:Yup.number().required("الحد الاقصي للحضور مطلوب").min(0)
        })}
        onSubmit={(values, { resetForm }) => {
          const {
            title,
            courseLink,
            courseAttachment,
            courseDescription,
            price,
            academicYear,
            unitId,
          } = values;
        
          if (courseLink.includes("drive")) {
            values.poster = courseLink;
          } else {
            const youtubeVideoId = getYouTubeID(courseLink);
            const poster = `https://i.ytimg.com/vi/${youtubeVideoId}/hq720.jpg`;
            values.poster = poster;
          }
          values.isFree = isFree;
          authorizedAPIs
            .put(`/course/edit/${_id}`, values)
            .then((res) => {
              
              dispatch(
                showAlert("تم تعديل الكورس بنجاح", "success")
              );
              navigate("/course")
            })
            .catch((err) => {
              console.log(err.message);
              err.response.data.message === "does not have this authority" &&
                dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
              navigate("/course")

            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: 600, margin: "auto" }}
          >
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="الاسم"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                type="title"
                value={values.title}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
               
              />
            </Grid>
            <Grid item xs={12}>
              <TextField

                error={Boolean(touched.courseLink && errors.courseLink)}
                fullWidth
                helperText={touched.courseLink && errors.courseLink}
                label="لينك الكورس"
                margin="normal"
                name="courseLink"
                onBlur={handleBlur}
                onChange={handleChange}
                type="courseLink"
                value={values.courseLink}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
             
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(
                  touched.courseAttachment && errors.courseAttachment
                )}
                fullWidth
                helperText={touched.courseAttachment && errors.courseAttachment}
                label="لينك ملف"
                margin="normal"
                name="courseAttachment"
                onBlur={handleBlur}
                onChange={handleChange}
                type="courseAttachment"
                value={values.courseAttachment}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
             
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-multiline-static"
                multiline
                maxRows={100}
                error={Boolean(
                  touched.courseDescription && errors.courseDescription
                )}
                fullWidth
                helperText={
                  touched.courseDescription && errors.courseDescription
                }
                label="الوصف"
                margin="normal"
                name="courseDescription"
                onBlur={handleBlur}
                onChange={handleChange}
                type="courseDescription"
                value={values.courseDescription}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
              
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.maxDisplayTimes && errors.maxDisplayTimes)}
                fullWidth
                helperText={touched.maxDisplayTimes && errors.maxDisplayTimes}
                label="الحد الاقصي للحضور"
                margin="normal"
                name="maxDisplayTimes"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.maxDisplayTimes}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
              
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && errors.price}
                label="السعر"
                margin="normal"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.price}
                variant="outlined"
                disabled={isSubmitting}
                InputLabelProps={{
                  shrink: true,
                }}
              
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFree}
                  value={isFree}
                  onChange={handleChangeIsFree}
                />
              }
              label="مجاني"
            />
            {isFree && (
              <Typography variant="h6" noWrap component="div" color="#1793b9">
                أصبح هذا المحتوى مجانا يستطيع أى مستخدم رؤية هذا المحتوى
              </Typography>
            )}
            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              value={values.academicYear}
              changeUnit={changeUnit}
            />
            <SelectionUnit
              option={units}
              handleChange={handleChange}
              value={values.unitId}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              تعديل 
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
}