import React, { useEffect, useState } from "react";
import PagesRoutes from "../pages/index";
import AuthenticationRoutes from "../pages/authentication";
import { authorizedAPIs } from "../API/axiosSetup";
import { LinearProgress } from "@mui/material";
// import Cookies from 'js-cookie'

const Handleauthentication = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [authority, setAuthority] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // const handleLogOut = () => Cookies.remove(process.env.REACT_APP_TOKEN_NAME);

  useEffect(() => {
    authorizedAPIs
      .post("/authentication/checkAuth")
      .then((res) => {
        setIsLogged(true);
        setIsLoading(false);
        setAuthority(res.data.authority);
      })
      .catch((err) => {
        setIsLogged(false);
        setIsLoading(false);

        console.log({ err });
        // handleLogOut();
      });
  }, []);

  return isLoading ? (
    <LinearProgress />
  ) : isLogged ? (
    <PagesRoutes authority={authority} />
  ) : (
    <>
      <AuthenticationRoutes />
    </>
  );
};

export default Handleauthentication;
