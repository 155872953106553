import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import QueueIcon from '@mui/icons-material/Queue';
import { authorizedAPIs } from "../API/axiosSetup";
import { showAlert } from "../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';


function AddCourseParts({ courseId }) {
    const [links, setLinks] = useState([{ title: "", description: "", link: "" }])
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const handleClick = () => {
        setLinks([...links, { title: "", description: "", link: "" }])
    }
    const handleChange = (e, i) => {
        const { name, value } = e.target
        const onchangeVal = [...links]
        onchangeVal[i][name] = value
        setLinks(onchangeVal)
    }
    const handleDelete = (i) => {
        const deleteVal = [...links]
        deleteVal.splice(i, 1)
        setLinks(deleteVal)
    }

    const handleSubmit = () => {
        console.log(links);
        authorizedAPIs.put(`/course/admin/addCourseLink/${courseId}`, {links})
            .then((res) => {
                dispatch(showAlert("تم اضافة الفديوهات بنجاح", "success"));
                navigate("/course")

            })
            .catch((err) => {
                console.log(err);
                dispatch(showAlert("لم يتم اضافة الفديوهات بنجاح", "error"));
                // navigate("/course")
            });
    }


    return (
        <div>

            {
                links.map(({ title, description, link }, i) =>
                    <div style={{ display: "flex" }} key={i}>
                        <div style={{ width: "-webkit-fill-available" }}>
                            <TextField
                                style={{ marginTop: "20px" }}
                                type="string"
                                variant="outlined"
                                fullWidth
                                label={`اسم الجزء ${i + 1}`}
                                name="title"
                                value={title} onChange={(e) => handleChange(e, i)}
                            />

                            <TextField
                                style={{ margin: "20px 0" }}
                                type="string"
                                variant="outlined"
                                fullWidth
                                label={`وصف الجزء ${i + 1}`}
                                name="description"
                                value={description} onChange={(e) => handleChange(e, i)}
                            />
                            <TextField
                                style={{ marginBottom: "20px" }}
                                type="string"
                                variant="outlined"
                                fullWidth
                                label={`لينك الجزء ${i + 1}`}
                                name="link"
                                value={link} onChange={(e) => handleChange(e, i)}
                            />
                        </div>
                        <Button onClick={() => handleDelete(i)}><DeleteIcon style={{ color: 'red' }} /></Button>
                    </div>
                )
            }
            <Button
                variant="contained"
                onClick={handleClick}
                style={{ background: "green" }}
            >
                <QueueIcon />
            </Button>

            <Button
                style={{ marginTop: 30 }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                اضافة
            </Button>

        </div>
    )
}
export default AddCourseParts;