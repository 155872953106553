import React, { useState, useEffect, useCallback } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../components/Table/table";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { authorizedAPIs } from "../API/axiosSetup";
import SelectionAcademicYears from "../components/selectionAcademicYears";
import { showAlert } from "../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import UnauthorizedAlert from "./UnauthorizedAlert";
import { useNavigate } from "react-router-dom";

function AllExamAndExercises({
  path,
  initialHeadCells,
  title,
  api,
  type,
  authority,
  addToUser,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [All, setAll] = useState([]);
  const academicYears = [
    { code: "All", label: "الكل" },
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];

  const [academicYear, seAcademicYear] = useState("All");
  const [unauthorized, setUnauthorized] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [numberOfExercise, setNumberOfExercise] = useState();

  const handleChange = (e) => {
    seAcademicYear(e.target.value);
  };

  const changeUnit = useCallback(() => {
    const skip = page * rowsPerPage;
    authorizedAPIs
      .get(
        `${api}?limit=${rowsPerPage}&skip=${skip}&academicYear=${academicYear}`
      )
      .then((res) => {
        console.log(res.data);
        setNumberOfExercise(res.data.numberOfExercise);
        const date = [...res.data.result];
        date.forEach((project) => {
          project.startDate = new Date(project.startDate).toLocaleString();
          project.endDate = new Date(project.endDate).toLocaleString();
        });
        setAll(date);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
        err.response.data.message === "does not have this authority" &&
          setUnauthorized("لا تمتلك هذه الصلاحية");
      });
  }, [academicYear]);

  useEffect(() => {
    const skip = page * rowsPerPage;

    changeUnit();
    // authority !== "admin" &&
    //   authorizedAPIs.get(`user/getAuthorityByAuthName/${type}`).then((res) => {
    //     setIsEdit(res.data.subAuthorizes[type].edit);
    //     setIsDelete(res.data.subAuthorizes[type].delete);
    //   });
  }, [academicYear, rowsPerPage, page]);

  const handleDelete = async (_id) => {
    return await authorizedAPIs.delete(`/exercises/delete/${_id}`).then(() => {
      dispatch(showAlert("تم الحذف بنجاح", "success"));
    });
  };

  const addQuestion = () => {
    authority === "admin"
      ? navigate(`${path}create`)
      : authorizedAPIs
          .get(`user/getAuthorityByAuthName/${type}`)
          .then((res) => {
            res.data.subAuthorizes[type].create
              ? navigate(`${path}create`)
              : dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
          });
  };

  return (
    <div>
      {unauthorized ? (
        <UnauthorizedAlert unauthorized={unauthorized} />
      ) : isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {title ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Button
                style={{ height: "45px", fontSize: "18px" }}
                variant="contained"
                onClick={addQuestion}
              >
                {title}
              </Button>
              <SelectionAcademicYears
                option={academicYears}
                handleChange={handleChange}
                changeUnit={changeUnit}
                value={academicYear}
              />
            </div>
          ) : (
            <SelectionAcademicYears
              option={academicYears}
              handleChange={handleChange}
              changeUnit={changeUnit}
              value={academicYear}
            />
          )}

          <EnhancedTable
            withDelete={addToUser ? "" : isDelete ? true : false}
            withEdit={addToUser ? "" : isEdit ? true : false}
            handleDeleteAPI={handleDelete}
            initialRows={All}
            headCells={initialHeadCells}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            page={page}
            setPage={setPage}
            path={path}
            count={numberOfExercise}
          />
        </>
      )}
    </div>
  );
}

export default AllExamAndExercises;
