import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
// Icons
import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import CustomTableRow from "./CustomTableRow";

import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { InputLabel, NativeSelect } from "@mui/material";
import AlertWithOptions from "../AlertWithOptions";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({
  withSearch = false,
  withEdit,
  withDelete,
  withInput,
  withBlock,
  initialRows,
  headCells,
  path,
  handleDeleteAPI,
  withConfirm,
  manyAction,
  manyActionLabel,
  getStudentForAllowExercise,
  getStudentForAllowExam,
  getStudentInExam,
  getStudentInExercise,
  courses,
  user_id,
  onBlur,
  inputKey,
  onChange,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
  page,
  setPage,
  count,
}) {
  const dispatch = useDispatch();

  // const rowsPerPageOptions = [2, 5, 10, 25, 50, 100, 150, 200, 250, 300]

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]); // limit
  const [rows, setRows] = useState([]);
  const [searchElement, setSearchElement] = useState("");
  const [isShown, setIsShowen] = useState(false);
  const [deletedIndex, setSeletedIndex] = useState();

  const handleDelete = async (index) => {
    setSeletedIndex(index);
    setIsShowen(true);
  };

  const handleDeleteConfirmation = async (deletedIndex) => {
    const splices_rows = [...rows];
    await handleDeleteAPI([splices_rows[deletedIndex]._id])
      .then((res) => {
        splices_rows.splice(deletedIndex, 1);
        setRows([...splices_rows]);
      })
      .catch((err) => {
        console.log({ err });
        err.response.data === "this unit used in course or exercise"
          ? dispatch(showAlert("تستخدم هذه الوحدة في تمرين او كورس", "warning"))
          : err.response.data ===
            "there is a relation between this course and exercise"
          ? dispatch(showAlert("يستخدم هذه الكورس في تمرين  ", "warning"))
          : err.response.data.message === "does not have this authority" &&
            dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
      });
    setIsShowen(false);
  };

  useEffect(() => {
    setRows(initialRows);
    setSearchElement(headCells[headCells.length - 1].id);
  }, [initialRows, headCells]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function handleClick(event, _id) {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  ////////////////// search logic ////////////////////////

  const requestSearch = (searchedVal) => {
    const filteredRows = initialRows.filter((row) => {
      return row[searchElement]
        .toString()
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  return (
    <Box sx={{ width: "100%", padding: "24px 0" }}>
      <AlertWithOptions
        isShown={isShown}
        action={() => handleDeleteConfirmation(deletedIndex)}
        handleClose={() => setIsShowen(false)}
        message={<>هل تريد حقًا حذفه </>}
      />

      <Paper sx={{ width: "100%", mb: 2 }}>
        {initialRows.length > 0 && withSearch && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <TextField
              onChange={(e) => requestSearch(e.target.value)}
              sx={{ width: "80%" }}
              id="input-with-sx"
              label="بحث"
              variant="standard"
            />
            <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <Box>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                search by
              </InputLabel>
              <NativeSelect
                onChange={(e) => setSearchElement(e.target.value)}
                defaultValue={searchElement}
              >
                {headCells.map(
                  ({ id, label }) =>
                    id !== "edit" && (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    )
                )}
              </NativeSelect>
            </Box>
          </Box>
        )}
        {selected.length > 0 && (
          <EnhancedTableToolbar
            getStudentForAllowExercise={getStudentForAllowExercise}
            selected={selected}
            manyAction={manyAction}
            manyActionLabel={manyActionLabel}
            getStudentForAllowExam={getStudentForAllowExam}
            getStudentInExam={getStudentInExam}
            getStudentInExercise={getStudentInExercise}
          />
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750, padding: 0 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              withDelete={withDelete}
              withInput={withInput}
              withConfirm={withConfirm}
              headCells={headCells}
              withEdit={withEdit}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow
                      withDelete={withDelete}
                      withBlock={withBlock}
                      index={index}
                      withEdit={withEdit}
                      withInput={withInput}
                      path={path}
                      handleDelete={handleDelete}
                      key={labelId}
                      row={row}
                      cellsInTheRightOrder={headCells}
                      courses={courses}
                      user_id={user_id}
                      onBlur={onBlur}
                      onChange={onChange}
                      inputKey={inputKey}
                    >
                      {withConfirm && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(event) => handleClick(event, row._id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                    </CustomTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={"عدد الصفوف"}
        />
      </Paper>
    </Box>
  );
}
