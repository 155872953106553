import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { authorizedAPIs } from "../../API/axiosSetup";
import { useParams } from "react-router-dom";
import academicYears from "../../API/academicYears.json";

function OneExercise() {
  const { _id } = useParams();
  const [exercise, setExercise] = useState({});
  const [isFree, setIsFree] = useState(false);

  useEffect(() => {
    authorizedAPIs
      .get(`/exercises/admin/getOne/${_id}`)
      .then((res) => {
        setExercise(res.data);
        setIsFree(res.data.isFree);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [_id]);

  return (
    <>
      <Box
        sx={{
          boxShadow: 3,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          p: 1,
          m: 1,
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
          margin: "40px 9px",
        }}
      >
        <Typography variant="h4" component="h2">
          الاسم: <span>{exercise.title}</span>
        </Typography>
        <Typography variant="h4" component="h2">
          المرحلة الدراسية: <span>{academicYears[exercise.academicYear]}</span>
        </Typography>

        <Typography variant="h4" component="h2">
          السعر: <span>{isFree ? "مجانى" : exercise.price + " جنية"}</span>
        </Typography>
      </Box>
      <iframe width={"100%"} height={900} src={exercise.questionLink}></iframe>
    </>
  );
}

export default OneExercise;
