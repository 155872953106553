import React, { useState, useEffect, useCallback } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import EnhancedTable from "../../components/Table/table";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { authorizedAPIs } from "../../API/axiosSetup";
import { showAlert } from "../../Redux/actions/viewAlert";
import { useDispatch } from "react-redux";

import SelectionAcademicYears from "../../components/selectionAcademicYears";
import { Box, Typography } from "@mui/material";
import UnauthorizedAlert from "../../components/UnauthorizedAlert";
import { useNavigate } from "react-router-dom";

const initialHeadCells = [
  {
    id: "title",
    numeric: false,
    label: "الاسم",
  },
  {
    id: "academicYear",
    numeric: false,
    label: "المرحلة الدراسية",
  },
  {
    id: "price",
    numeric: true,
    label: "السعر",
  },
];

function Courses({ authority }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const academicYears = [
    { code: "All", label: "الكل" },
    { code: "M1", label: "الصف الاول الاعدادي" },
    { code: "M2", label: "الصف الثاني الاعدادي" },
    { code: "M3", label: "الصف الثالث الاعدادي" },
    { code: "H1", label: "الصف الاول الثانوي" },
    { code: "H2", label: "الصف الثاني الثانوي" },
    { code: "H3", label: "الصف الثالث الثانوي" },
  ];
  const rowsPerPageOptions = [5, 10, 25, 50, 100, 150, 200, 250, 300]
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [counter, setCounter] = useState();


  const [academicYear, seAcademicYear] = useState("All");
  const [unauthorized, setUnauthorized] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [isDelete, setIsDelete] = useState(true);

  const handleChange = (e) => {
    seAcademicYear(e.target.value);
  };

  const changeUnit = useCallback(() => {
    const skip = (page) * rowsPerPage;

    authorizedAPIs
      .get(`/course/admin/getMany?limit=${rowsPerPage}&skip=${skip}&academicYear=${academicYear}`)
      .then((res) => {
        console.log(res.data);
        setCourses(res.data.result);
        setCounter(res.data.counter)
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        err.response.data.message === "does not have this authority" &&
          setUnauthorized("لا تمتلك هذه الصلاحية");
        setIsLoading(false);
      });
  }, [academicYear, page, rowsPerPage]);


  useEffect(() => {
    changeUnit();
    authority !== "admin" &&
      authorizedAPIs.get(`user/getAuthorityByAuthName/course`).then((res) => {
        setIsEdit(res.data.subAuthorizes.course.edit);
        setIsDelete(res.data.subAuthorizes.course.delete);
      });
  }, [academicYear,page, rowsPerPage]);

  const handleDelete = async (_id) => {
    return await authorizedAPIs.delete(`/course/delete/${_id}`).then(() => {
      dispatch(showAlert("تم حذف الكورس بنجاح", "success"));
    });
  };

  const addCourse = () => {
    authority === "admin"
      ? navigate("/course/create")
      : authorizedAPIs.get(`user/getAuthorityByAuthName/course`).then((res) => {
        res.data.subAuthorizes.course.create
          ? navigate("/course/create")
          : dispatch(showAlert("لا تمتلك هذه الصلاحية", "info"));
      });
  };

  return (
    <div>
      <Box textAlign="center">
        <Typography style={{ fontSize: "30px", color: "#010004" }}>
          الكورسات
        </Typography>
      </Box>

      {unauthorized ? (
        <UnauthorizedAlert unauthorized={unauthorized} />
      ) : (
        <>
          <div style={{
            display: "grid",
            gridTemplateColumns:"1fr 4fr",
            gap:"20px",
            alignItems:"center",
            }}>
          <Button
            style={{ height: "45px" , fontSize: "18px"}}
            variant="contained"
            onClick={addCourse}
          >
            اضافة كورس
          </Button>
          <SelectionAcademicYears
            option={academicYears}
            handleChange={handleChange}
            changeUnit={changeUnit}
            value={academicYear}
          />
        </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <EnhancedTable
          withDelete={isDelete ? true : false}
          withEdit={isEdit ? true : false}
          handleDeleteAPI={handleDelete}
          initialRows={courses}
          headCells={initialHeadCells}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          setPage={setPage}
          count={counter}
          path={"/course/"}
        />
      )}
    </>
  )
}
    </div >
  );
}

export default Courses;
