import React from "react";
import '../style/home.css'

export default function Home() {
  return (
    <div className="home">
      <p>Welcome To course Master</p>
      <p>By</p>
      <p>Oriented Coders</p>
    </div>
  );
}


