import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authorizedAPIs } from "../../API/axiosSetup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import academicYears from "../../API/academicYears.json";
import PDFAndVideosHandler from "./PDFAndVideosHandler";
import CardMedia from "@mui/material/CardMedia";
import UsersInCourse from "../../components/UsersInCourse";

export default function OneCourse() {
  const { _id } = useParams();
  const [course, setCourse] = useState({});
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    authorizedAPIs
      .get(`/course/admin/getOne/${_id}`)
      .then((res) => {
        console.log({ res });
        setCourse({ ...res.data });
        setVideos(res.data.courseLinks)
      })
      .catch((error) => { });
  }, []);

  const theme = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 1024,
        desktop: 1200,
      },

    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          maxWidth: 1000,
          margin: "auto",
          transition: "0.3s",
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
        }}
      >
        <CardContent>
          <PDFAndVideosHandler link={course.courseLink} />
          <Typography
            sx={{
              margin: "20px 0 10px",
              fontWeight: "bold",
              fontSize: {
                mobile: 30,
                tablet: 36,
                laptop: 40,
                desktop: 48,
              },
            }}
            gutterBottom
          >
            {course.title}
          </Typography>

          <Typography
            variant={"h4"}
            sx={{
              fontSize: {
                mobile: 24,
                tablet: 30,
                laptop: 36,
                desktop: 40,
              },
            }}
          >
            {course.courseDescription}
          </Typography>
          <Box sx={{ display: "flex", gap: 20, margin: "20px 0" }}>
            <Typography gutterBottom variant="h5" component="div" color="green">
              {academicYears[course.academicYear]}
            </Typography>
            <Typography gutterBottom variant="h5" component="div" color="red">
              {course.isFree ? "مجاني" : course.price + " جنية"}
            </Typography>
          </Box>
          <Typography gutterBottom variant="h5" component="div" >
            الحد الاقصي للحضور : {course.maxDisplayTimes}
          </Typography>
          {videos.map((video, index) => {
            return (<div style={{ margin: "50px 0" }} key={index} >
              <PDFAndVideosHandler link={video.link} />
              <Typography
                sx={{
                  margin: "20px 0 10px",
                  fontWeight: "bold",
                  fontSize: {
                    mobile: 30,
                    tablet: 36,
                    laptop: 40,
                    desktop: 48,
                  },
                }}
                gutterBottom
              >
                {video.title}
              </Typography>

              <Typography
                variant={"h4"}
                sx={{
                  fontSize: {
                    mobile: 24,
                    tablet: 30,
                    laptop: 36,
                    desktop: 40,
                  },
                }}
              >
                {video.description}
              </Typography>
            </div>)
          })}
          <PDFAndVideosHandler attachment={course.courseAttachment} />
        </CardContent>
      </Card>
      <UsersInCourse _id={course._id} />
    </ThemeProvider>
  );
}
