import { Route, Routes } from "react-router-dom";
import React from "react";
import PersistentDrawerRight from "../components/mainlayout";
import { StyledEngineProvider } from "@mui/material/styles";
import Home from "./Home";
import Units from "./units/Units";
import AddUnit from "./units/AddUnit";
import Courses from "./courses/Courses";
import AddCouese from "./courses/AddCourse";
import Exercises from "./exercise/Exercises";
import AddExercise from "./exercise/AddExercise";
import OneCourse from "./courses/OneCourse";
import EditCourse from "./courses/EditCourse";
import EditUnit from "./units/EditUnit";
import Exams from "./exams/Exams";
import AddExam from "./exams/AddExam";
import OneExercise from "./exercise/oneExercise";
import OneExam from "./exams/OneExam";
import ExercisesWithSolutions from "./exercise/ExercisesWithSolutions";
import UsersInExercise from "./exercise/UsersInExercise";
import UserSolution from "../components/UserSolutionForExamAndExercise";
import ExamsWithSolutions from "./exams/ExamsWithSolutions";
import UsersInExam from "./exams/UsersInExam";
import Users from "./users/Users";
import PendingUsers from "./Pending users/PendingUsers";
import OneUser from "./oneUser";
import AddCourseToUsers from "./courses/AddCourseToUsers";
import UsersNotInCourse from "./courses/UsersNotInCourse";
import AddExerciseToUsers from "./exercise/AddExerciseToUsers";
import UsersNotInExercise from "./exercise/UsersNotInExercise";
import AddExamToUsers from "./exams/AddExamToUser";
import UsersNotInExam from "./exams/UsersNotInExam";
import EditExercise from "./exercise/EditExercise";
import EditExam from "./exams/EditExam";
import Assistants from "./assistants/Assistants";
import AddAssistants from "./assistants/AddAssistants";
import EditUser from "./users/EditUser";
import OneAssistant from "./assistants/OneAssistant";
import EditAssistant from "./assistants/EditAssistant";
import DeleteCourseFromUser from "./courses/DeleteCourseFromUser";
import UsersInCourse from "./courses/UsersInCourse";
import DeleteExerciseFromUsers from "./exercise/DeleteExerciseFromUsers";
import DeleteExamFromUser from "./exams/DeleteExamFromUser";
import Redirect from "./Redirect";
import Settings from "./Settings";


const PagesRoutes = ({authority}) => {
  return (
    <StyledEngineProvider injectFirst>
      <PersistentDrawerRight authority={authority}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/unit" element={<Units authority={authority} />} />
          <Route exact path="/unit/create" element={<AddUnit />} />
          <Route exact path="/unit/edit/:_id" element={<EditUnit />} />

          <Route exact path="/course" element={<Courses authority={authority}/>} />
          <Route exact path="/course/create" element={<AddCouese  />} />
          <Route exact path="/course/:_id" element={<OneCourse />} />
          <Route exact path="/course/edit/:_id" element={<EditCourse />} />

          <Route exact path="/exercise" element={<Exercises authority={authority} />} />
          <Route exact path="/exercise/create" element={<AddExercise />} />
          <Route exact path="/exercise/edit/:_id" element={<EditExercise />} />
          <Route exact path="/exercise/:_id" element={<OneExercise />} />
          <Route exact path="/exercises-solutions" element={<ExercisesWithSolutions />} />
          <Route exact path="/exercises-solutions/:_id" element={<UsersInExercise />} />
          <Route exact path="/user/:_id" element={<UserSolution />} />

          <Route exact path="/exam" element={<Exams authority={authority} />} />
          <Route exact path="/exam/create" element={<AddExam />} />
          <Route exact path="/exam/edit/:_id" element={<EditExam />} />
          <Route exact path="/exam/:_id" element={<OneExam />} />
          <Route exact path="/exams-solutions" element={<ExamsWithSolutions />} />
          <Route exact path="/exams-solutions/:_id" element={<UsersInExam />} />

          <Route exact path="/users" element={<Users authority={authority}/>} />
          <Route exact path="/users/edit/:_id" element={<EditUser/>} />
          <Route exact path="/Pending-Users" element={<PendingUsers/>} />
          <Route exact path="/users/:_id" element={<OneUser/>} />
          <Route exact path="/Pending-Users/:_id" element={<OneUser/>} />

          <Route exact path="/add-course-to-user" element={<AddCourseToUsers authority={authority} />} />
          <Route exact path="/add-course-to-user/:_id" element={<UsersNotInCourse/>} />

          <Route exact path="/delete-course-from-user" element={<DeleteCourseFromUser />} />
          <Route exact path="/delete-course-from-user/:_id" element={<UsersInCourse/>} />


          <Route exact path="/add-exercise-to-user" element={<AddExerciseToUsers/>} />
          <Route exact path="/add-exercise-to-user/:_id" element={<UsersNotInExercise/>} />

          <Route exact path="/delete-exercise-from-user" element={<DeleteExerciseFromUsers/>} />
          <Route exact path="/delete-exercise-from-user/:_id" element={<UsersInExercise/>} />

          <Route exact path="/add-exam-to-user" element={<AddExamToUsers/>} />
          <Route exact path="/add-exam-to-user/:_id" element={<UsersNotInExam/>} />

          <Route exact path="/delete-exam-from-user" element={<DeleteExamFromUser/>} />
          <Route exact path="/delete-exam-from-user/:_id" element={<UsersInExam/>} />
          
          <Route exact path="/assistant" element={<Assistants/>} />
          <Route exact path="/add-assistant" element={<AddAssistants />} />
          <Route exact path="/assistant/:_id" element={<OneAssistant />} />
          <Route exact path="/assistant/edit/:_id" element={<EditAssistant />} />
          <Route exact path="/settings" element={<Settings />} />

          <Route path='*' element={<Redirect to={"/"} />} />

        </Routes>
      </PersistentDrawerRight>
    </StyledEngineProvider>
  );
};

export default PagesRoutes;